let currentIndex = -1;
let currentOtherIndex = 0;
let cloneAmount = 4;
let transformAmount = 110;
let currentSlider = 'shops';
let sliders = ['shops', 'websites'];
let originalAmount = { shops: -1, websites: -1 };

document.querySelector(".slider__arrow--left").addEventListener("click", () => {
    slide(currentIndex - 1);
});

document.querySelector(".slider__arrow--right").addEventListener("click", () => {
    slide(currentIndex + 1);
});

sliders.forEach(sliderType => {
    cloneSlides(sliderType);
    addPointerEvents(sliderType);

    document.querySelector(`.slider__category--${sliderType}`).addEventListener("click", () => {
        changeSlider(sliderType);
    });

    document.querySelectorAll(`.slider__dots--${sliderType} .slider__dot`).forEach(dot => {
        dot.addEventListener("click", (e) => {
            let dots = document.querySelectorAll(`.slider__dots--${sliderType} .slider__dot`);
            for (i = 0; i < dots.length; i++) {
                if (dots[i] === dot) {
                    slide(i, currentSlider, true);
                    break;
                }
            }
        });
    });
});

slide(0, 'shops');
currentIndex--;
slide(0, 'websites');

function slide(n, slider = null, dot = false) {
    let slides = document.querySelectorAll(`.slider__items--${slider ?? currentSlider} .slider__item`);
    let dots = document.querySelectorAll(`.slider__dots--${slider ?? currentSlider} .slider__dot`);

    if (dot) n = currentIndex + (n - currentIndex % originalAmount[slider ?? currentSlider]);
    n = getCorrectIndex(n, slides.length);

    if (currentIndex === n) return;

    for (i = 0; i < slides.length; i++) {
        removeSlideStyles(slides[i]);
    }
    dots.forEach(dot => {
        dot.classList.remove("slider__dot--active");
    });

    setActiveSlideStyle(slides[n]);
    dots[getDotIndex(n)].classList.add("slider__dot--active");

    for (i = 1; i <= originalAmount[slider ?? currentSlider]; i++) {
        setSlideStyle(slides[getCorrectIndex(n - i, slides.length)], -i);
        setSlideStyle(slides[getCorrectIndex(n + i, slides.length)], i);
    }

    currentIndex = n;
}

function changeSlider(newSlider) {
    if (newSlider === currentSlider) return;

    let temp = currentOtherIndex;
    currentOtherIndex = currentIndex;
    currentIndex = temp;
    currentSlider = newSlider;

    sliders.forEach(sliderType => {
        let dots = document.querySelectorAll(`.slider__dots`);
        let slides = document.querySelectorAll(`.slider__items`);
        let categories = document.querySelectorAll(`.slider__category`);

        for (i = 0; i < slides.length; i++) {
            dots[i].style.display = "none";
            slides[i].style.display = "none";
            categories[i].classList.remove("slider__category--active");
        }
    });

    document.querySelector(`.slider__dots--${newSlider}`).style.display = '';
    document.querySelector(`.slider__items--${newSlider}`).style.display = '';
    document.querySelector(`.slider__category--${newSlider}`).classList.add("slider__category--active");
}

function cloneSlides(sliderType) {
    let slides = document.querySelectorAll(`.slider__items--${sliderType} .slider__item`);
    let slidesContainer = document.querySelector(`.slider__items--${sliderType}`);
    originalAmount[sliderType] = slides.length;

    for (i = 0; i < cloneAmount; i++) {
        for (j = 0; j < slides.length; j++) {
            let clone = slides[j].cloneNode(true);
            removeSlideStyles(clone);
            slidesContainer.appendChild(clone);
        }
    }
}

function removeSlideStyles(slide) {
    slide.classList.remove("slider__item--active");
    slide.classList.remove("slider__item--side");
    slide.style.transform = "";
}

function getDotIndex(n) {
    return n % originalAmount[currentSlider];
}

function getCorrectIndex(index, length) {
    while (index < 0) {
        index += length;
    }
    while (index >= length) {
        index -= length;
    }

    return index;
}

function setSlideStyle(slide, n) {
    slide.classList.add("slider__item--side");
    slide.style.transform = `translate(${(transformAmount * n)}%, 0) scale(0.85) rotateY(${(n < 0 ? 25 : -25)}deg)`
}

function setActiveSlideStyle(slide) {
    slide.classList.add("slider__item--active");
}

function addPointerEvents(sliderType) {
    let slides = document.querySelectorAll(`.slider__items--${sliderType} .slider__item`);

    slides.forEach(slide => {
        slide.addEventListener("pointermove", (e) => {
            if (!slide.classList.contains('slider__item--active')) return;
            const box = slide.getBoundingClientRect();
            const centerPosition = {
                x: box.left + box.width / 2,
                y: box.top + box.height / 2,
            };
            let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
            slide.querySelector('.slider__item__monitor').style.transform = `rotateY(${angle}deg)`;
            slide.querySelector('.slider__item__image-container').style.transform = `rotateY(${angle}deg)`;
        });

        slide.addEventListener("pointerout", (e) => {
            if (!slide.classList.contains('slider__item--active')) return;
            slide.querySelector('.slider__item__monitor').style.transform = '';
            slide.querySelector('.slider__item__image-container').style.transform = '';
        });
    });
}
